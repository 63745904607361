




























































































import { LoadingStatusType, UserActionMenu, UserPackageType } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { ConvertUserPackageType } from "@/model/user/userDetailModel";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const StoreAccountingFirm = namespace("AccountingFrim");
const StoreUser = namespace("User");
const StoreMerchant = namespace("Merchant");

@Component
export default class ModalConvertUserAFP extends Vue {
  @Prop({ required: true }) readonly item!: any;
  @Prop({ default: false }) readonly convertMerchant!: boolean;

  @StoreAccountingFirm.Getter
  private getAccountingFirms!: Array<any>;

  @StoreAccountingFirm.Getter
  private getAccountingFirmsLoadingStatus!: LoadingStatusType;

  @StoreAccountingFirm.Action
  private DoGetAccountingFirms!: () => void;
  @StoreUser.Action
  private DoConvertUserPackageType!: (input: ConvertUserPackageType, actionType: number) => void;
  @StoreMerchant.Action
  private DoGetConvertMerchantPackageType!: (input: any) => void;

  private isShowConvertUserAFPModal = true;
  private codeAFPDropdownModelList: any = [];
  private codeAFPDropdownModelSelectValue: any = null;
  private isSelectedAFPCode = true;

  created() {
    this.DoGetAccountingFirms();
  }

  @Watch("getAccountingFirmsLoadingStatus", { immediate: true })
  getAccountingFirmsLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      let codeAFPDropdownModelListObj = this.getAccountingFirms.length > 0 ? NewObject(this.getAccountingFirms) : [];
      let codeAFPDropdownModelListObjNew: any = [];
      codeAFPDropdownModelListObj.forEach((element: any) => {
        element["textDisplay"] = `${element.internalCode} - ${element.name}`;
        codeAFPDropdownModelListObjNew.push(element);
      });
      this.codeAFPDropdownModelList = codeAFPDropdownModelListObjNew;
    }
  }

  @Watch("isShowConvertUserAFPModal")
  isShowConvertUserAFPModalChanged(newVal: boolean) {
    if (!this.isShowConvertUserAFPModal) {
      this.$emit("update:isShowConvertUserAFPModal", false);
    }
  }

  public GetValueSelect(selectedValue: any) {
    this.codeAFPDropdownModelSelectValue = this.codeAFPDropdownModelList?.find((x: any) => x.id === selectedValue);
    this.isSelectedAFPCode = true;
  }

  HideModal() {
    this.isShowConvertUserAFPModal = false;
  }

  ConfirmConvert() {
    if (this.item.userPackageType === UserPackageType.Normal) {
      this.ConvertNormalToAFP();
    } else {
      this.ConvertAFPToNormal();
    }
  }

  ConvertNormalToAFP() {
    if (this.codeAFPDropdownModelSelectValue) {
      this.isSelectedAFPCode = true;
      const input: ConvertUserPackageType = {
        userId: this.item.id,
        accountingFirmId: this.codeAFPDropdownModelSelectValue.id,
        userPackageType: UserPackageType.AFP,
        userName: this.item.userName,
        internalCode: "",
      };
      this.DoConvertUserPackageType(input, UserActionMenu.ConvertToAFP);
      this.HideModal();
    } else {
      this.isSelectedAFPCode = false;
    }
  }
  ConvertAFPToNormal() {
    const input: ConvertUserPackageType = {
      userId: this.item.id,
      accountingFirmId: this.item.afpDetail.id,
      userPackageType: UserPackageType.Normal,
      userName: this.item.userName,
      internalCode: this.item.afpDetail.internalCode,
    };
    this.DoConvertUserPackageType(input, UserActionMenu.ConvertToNormal);
    this.HideModal();
  }
  CheckUserPackageTypeAFP() {
    return this.item.userPackageType === UserPackageType.AFP;
  }
  CheckUserPackageTypeNormal() {
    return this.item.userPackageType === UserPackageType.Normal;
  }
}
