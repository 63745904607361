






import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MerchantTable from "./MerchantTable.vue";

const StoreMerchant = namespace("Merchant");

@Component({ components: { MerchantTable } })
export default class Merchant extends Vue {}
