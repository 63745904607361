

































































































































import ModalCancelPeakPayment from "@/components/modal/merchant/ModalCancelPeakPayment.vue";
import ModalSwapMerchant from "@/components/modal/merchant/ModalSwapMerchant.vue";
import { LoadingStatusType, PaymentStatus, PeakPackage } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { MerchantTableViewModel } from "@/model/merchant//merchantTableViewModel";
import MerchantActionList from "@/views/merchant/MerchantActionList.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreMerchant = namespace("Merchant");

@Component({
  components: { MerchantActionList, ModalSwapMerchant, ModalCancelPeakPayment },
})
export default class MerchantTable extends Vue {
  private textSearch = "";
  private codeSearch = "";
  private textSearchPromotionCode = "";
  private pageNo = 1;
  private pageSize = 10;
  private table: any = {
    header: [],
    detail: [],
  };
  private pageNoList: Array<number> = [];
  private pageSizeList = [5, 10, 25, 50, 100];
  private countItem = 0;
  private totalPage = 0;

  @StoreMerchant.Action
  private DoGetMerchantTable!: (merchantTableViewModel: MerchantTableViewModel) => void;

  @StoreMerchant.Getter
  private getApproveUpgradePackageLoadingStatus!: any;

  @StoreMerchant.Getter
  private getMerchantDetailListModel!: any;

  @StoreMerchant.Getter
  private getHeaderMerchantTable!: any;

  @StoreMerchant.Getter
  private getMerchantTableLoadingStatus!: LoadingStatusType;

  @StoreMerchant.Getter
  private getSwapMerchantPackageLoadingStatus!: LoadingStatusType;

  @StoreMerchant.Getter
  private getCancelPeakPackageLoadingStatus!: LoadingStatusType;

  @StoreMerchant.Getter
  private getConvertMerchantPackageToAFPLoadingStatus!: LoadingStatusType;

  @StoreMerchant.Getter
  private getCountItemTable!: number;

  @Watch("getMerchantTableLoadingStatus", { immediate: true })
  getMerchantTableLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.table.header = NewObject(this.getHeaderMerchantTable);
      const merchantTableList: any =
        this.getMerchantDetailListModel.length > 0 ? NewObject(this.getMerchantDetailListModel) : [];
      this.table.detail = merchantTableList;
      this.countItem = this.getCountItemTable;
      this.totalPage = Math.ceil(this.getCountItemTable / this.pageSize);
      this.pageNoList = [];
      for (let index = 0; index < this.totalPage; index++) {
        this.pageNoList.push(index + 1);
      }
    }
  }

  @Watch("getApproveUpgradePackageLoadingStatus")
  getApproveUpgradePackageLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.GetMerchantTable(this.pageNo, this.pageSize);
    }
  }

  @Watch("pageNo")
  pageNoChanged(newVal: number) {
    this.GetMerchantTable(this.pageNo, this.pageSize);
  }

  @Watch("pageSize")
  pageSizeChanged(newVal: number) {
    this.GetMerchantTable(1, this.pageSize);
  }

  @Watch("getSwapMerchantPackageLoadingStatus")
  getSwapMerchantPackageLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.GetMerchantTable(this.pageNo, this.pageSize);
    }
  }

  @Watch("getCancelPeakPackageLoadingStatus")
  getCancelPeakPackageLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.GetMerchantTable(this.pageNo, this.pageSize);
    }
  }
  @Watch("getConvertMerchantPackageToAFPLoadingStatus")
  getConvertMerchantPackageToAFPLoadingStatusChanged(newVal: number) {
    if (newVal == LoadingStatusType.Success) {
      this.GetMerchantTable(this.pageNo, this.pageSize);
    }
  }

  created() {
    this.GetMerchantTable(this.pageNo, this.pageSize);
  }

  public GetMerchantTable(
    pageNo = this.pageNo,
    pageSize = this.pageSize,
    textSearch = this.textSearch,
    codeSearch = this.codeSearch,
    promotionCodeSearch = this.textSearchPromotionCode,
    isMain = false
  ) {
    const merchantTableViewModel: MerchantTableViewModel = {
      pageSize: pageSize,
      pageNo: pageNo,
      languageType: undefined,
      searchText: textSearch,
      codeSearch: codeSearch,
      promotionCodeSearch: promotionCodeSearch,
    };
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    this.DoGetMerchantTable(merchantTableViewModel);
  }

  public SearchMerchant() {
    this.GetMerchantTable(1, 10);
    this.pageNo = 1;
    this.pageSize = 10;
  }

  public GetPackageColorName(currentMainPackage: PeakPackage) {
    return currentMainPackage.GetPeakPackageClassColor();
  }
  public Back() {
    this.$router.push({
      name: "activityLog",
    });
  }
  public LabelNumber(index: number) {
    if (this.pageNo > 1) {
      return index + 1 + this.pageSize * (this.pageNo - 1);
    } else {
      return index + 1;
    }
  }
  public MerchantDetail(merchantId: number) {
    const routeData = this.$router.resolve({
      name: "merchantDetail",
      query: { merchantId: merchantId.toString() },
    });
    window.open(routeData.href, "_blank");
  }
  public GetStatusText(status: number, peakPaymentConnectV2Id: number) {
    const NONE_PAYMENT_CONNECT_V2_ID = 0;
    if (status === PaymentStatus.OnProcessing && peakPaymentConnectV2Id === NONE_PAYMENT_CONNECT_V2_ID) {
      return "-";
    }
    if (status === PaymentStatus.OnProcessing) {
      return "On Processing";
    } else if (status === PaymentStatus.Waiting) {
      return "Waiting";
    } else if (status === PaymentStatus.Approve) {
      return "Approve";
    }
  }
}
