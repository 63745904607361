






















































































import ModalCancelPeakPayment from "@/components/modal/merchant/ModalCancelPeakPayment.vue";
import ModalConfirmSwapMerchant from "@/components/modal/merchant/ModalConfirmSwapMerchant.vue";
import ModalConfirmTransferPackage from "@/components/modal/merchant/ModalConfirmTransferPackage.vue";
import ModalConvertPackageAFP from "@/components/modal/merchant/ModalConvertPackageAFP.vue";
import ModalSwapMerchant from "@/components/modal/merchant/ModalSwapMerchant.vue";
import ModalTransferPackage from "@/components/modal/merchant/ModalTransferPackage.vue";
import ModalConvertUserAFP from "@/components/modal/user/ModalConvertUserAFP.vue";
import {
  ConvertMerchantPackage,
  LoadingStatusType,
  MerchantActionMenu,
  PaymentStatus,
  PeakPackage,
  PeakPaymentConnectV2Id,
  PuunReceiptId,
  UserPackageType,
  WhtId,
} from "@/enums/enums";
import { MerchantDetailModel } from "@/model/merchant/merchantDetailModel";
import AuthService from "@/service/authService";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const auth = new AuthService();
const StoreMerchant = namespace("Merchant");
const StoreUser = namespace("User");

@Component({
  components: {
    ModalSwapMerchant,
    ModalTransferPackage,
    ModalCancelPeakPayment,
    ModalConfirmSwapMerchant,
    ModalConfirmTransferPackage,
    ModalConvertPackageAFP,
    ModalConvertUserAFP,
  },
})
export default class MerchantActionList extends Vue {
  @Prop({ required: true }) readonly item!: any;

  private isShowConfirmSwapMerchantModal = false;
  private isShowConvertPackageAFPModal = false;
  private isShowConvertUserAFPModal = false;
  private textConfirmSwapMerchant = "";
  private isShowConfirmTransferPackageModal = "";
  private merchantDropdownModelSelect: any = null;
  private remark = "";
  private asana = "";
  private items = [{ title: "Edit" }, { title: "Delete" }, { title: "Something else" }];

  @StoreMerchant.Getter
  private getConvertMerchantPackageData!: any;

  @StoreMerchant.Getter
  private getConvertMerchantPackageDataLoadingStatus!: LoadingStatusType;

  @StoreUser.Getter
  private getConvertUserPackageTypeLoadingStatus!: LoadingStatusType;

  @StoreMerchant.Getter
  private getMerchantTableLoadingStatus!: LoadingStatusType;

  @StoreMerchant.Action
  private DoSetMerchantDetailSelect!: (merchantDetailModel: MerchantDetailModel) => void;

  @StoreMerchant.Action
  private DoApproveUpgradePackage!: (input: any) => void;

  @StoreMerchant.Action
  private DoGetConvertMerchantPackageType!: (input: any) => void;

  private merchantAction: any = [];
  private showList = false;
  private isShowSwapMerchantModal = false;
  private isShowTransferPackageModal = false;
  private isShowCancelPaymentPackageModal = false;
  private superUserItem: any = null;
  merchantActionMenu = MerchantActionMenu;
  convertMerchantAFPMenuText = this.isAFPPackage()
    ? "เปลี่ยนประเภทกิจการ<br/>เป็นแพ็กเกจปกติ"
    : "เปลี่ยนประเภทกิจการ<br/>เป็นแพ็กเกจ AFP";

  constructor() {
    super();
    this.initializeMenuList();
  }

  public async initializeMenuList(): Promise<void> {
    const merchantPermission = await this.getMerchantPermissions();
    this.merchantAction = [
      {
        text: "สลับกิจการ",
        icon: "mdi-autorenew",
        actionValue: MerchantActionMenu.SwapPackage,
        permission: merchantPermission.swapPackage,
      },
      {
        text: "โอนย้ายวันคงเหลือ",
        icon: "mdi-swap-horizontal",
        actionValue: MerchantActionMenu.TransferPackage,
        permission: merchantPermission.tranferPackage,
      },
      {
        text: "ยกเลิกแพ็กเกจ",
        icon: "mdi-book-remove",
        actionValue: MerchantActionMenu.CancelPackage,
        permission: merchantPermission.cancelPackage,
      },
      {
        text: "รายการผู้ใช้งาน",
        icon: "mdi-format-list-bulleted-square",
        actionValue: MerchantActionMenu.User,
        permission: merchantPermission.user,
      },
      {
        text: "อนุมัติ",
        icon: "mdi-checkbox-marked-circle",
        actionValue: MerchantActionMenu.ApprovePackage,
        permission: merchantPermission.approvePackage,
      },
      {
        text: "เข้าดูเอกสาร",
        icon: "mdi-note-text",
        actionValue: MerchantActionMenu.ViewReceipt,
        permission: merchantPermission.viewReceipt,
      },
      {
        text: "เข้าดูหัก ณ ที่จ่าย",
        icon: "mdi-receipt",
        actionValue: MerchantActionMenu.ViewWitholdingTax,
        permission: merchantPermission.viewWitholdingTax,
      },
      {
        text: "",
        icon: "mdi-cube",
        actionValue: MerchantActionMenu.ConvertPackageAFP,
        permission: merchantPermission.convertMerchantPackageType,
      },
    ];
  }

  @Watch("isShowConfirmSwapMerchantModal")
  isShowConfirmSwapMerchantModalChanged(newVal: boolean) {
    if (!this.isShowConfirmSwapMerchantModal) {
      this.textConfirmSwapMerchant = "";
    }
  }

  @Watch("getConvertUserPackageTypeLoadingStatus")
  getConvertUserPackageTypeLoadingStatusChanged(newVal: number) {
    if (
      newVal == LoadingStatusType.Success &&
      this.getConvertMerchantPackageData.convertMerchantPackageTypeDetail.merchant.id === this.item.merchantId
    ) {
      this.DoGetConvertMerchantPackageType({ merchantId: this.item.merchantId });
    }
  }

  @Watch("getConvertMerchantPackageDataLoadingStatus")
  getConvertMerchantPackageDataLoadingStatusChanged(newVal: LoadingStatusType) {
    if (
      newVal === LoadingStatusType.Success &&
      this.getConvertMerchantPackageData.convertMerchantPackageTypeDetail.merchant.id === this.item.merchantId
    ) {
      if (this.getConvertMerchantPackageData.code === ConvertMerchantPackage.canNotConvert) {
        this.superUserItem = {
          ...this.getConvertMerchantPackageData.convertMerchantPackageTypeDetail.superAdmin,
          userPackageType: UserPackageType.Normal,
        };
        this.isShowConvertUserAFPModal = true;
      } else if (this.getConvertMerchantPackageData.code === ConvertMerchantPackage.notHaveSuperAdmin) {
        alert("กิจการนี้ไม่มี Super admin");
      } else {
        this.isShowConvertPackageAFPModal = true;
      }
    }
  }

  @Watch("getMerchantTableLoadingStatus", { immediate: true })
  getMerchantTableLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.GetConvertMerchantAFPMenuText();
    }
  }

  handleShowItem(actionItem: any) {
    const { actionValue } = actionItem;
    const { peakPaymentConnectV2Id, paymentStatus, puunReceiptId, whtId } = this.item;

    switch (actionValue) {
      case MerchantActionMenu.TransferPackage:
        return this.HideActionTransferPackage(paymentStatus);
      case MerchantActionMenu.CancelPackage:
        return this.HideActionCancelPackage(peakPaymentConnectV2Id);
      case MerchantActionMenu.ApprovePackage:
        return this.HideActionApprovePackage(paymentStatus, puunReceiptId);
      case MerchantActionMenu.ViewReceipt:
        return this.HideActionViewReceipt(paymentStatus, puunReceiptId);
      case MerchantActionMenu.ViewWitholdingTax:
        return this.HideActionViewTaxedDetail(paymentStatus, whtId);
      default:
        return true;
    }
  }

  handleItemClick(actionItem: any) {
    const { actionValue } = actionItem;
    switch (actionValue) {
      case MerchantActionMenu.SwapPackage:
        this.SwapMrchantPackage();
        break;
      case MerchantActionMenu.TransferPackage:
        this.TransferPackage();
        break;
      case MerchantActionMenu.CancelPackage:
        this.CancelPaymentPackage();
        break;
      case MerchantActionMenu.User:
        this.ChangeSuperuser(this.item.merchantId);
        break;
      case MerchantActionMenu.ApprovePackage:
        this.ApproveUpgradePackage();
        break;
      case MerchantActionMenu.ViewReceipt:
        this.ViewReceiptDetail();
        break;
      case MerchantActionMenu.ViewWitholdingTax:
        this.ViewTaxedDetail();
        break;
      case MerchantActionMenu.ConvertPackageAFP:
        this.ConvertPackageAFP();
        break;
    }
  }

  public async getMerchantPermissions() {
    const accessPermission = await auth.getPermissions();
    const permission = accessPermission["merchant"];
    return permission;
  }

  public ShowActionList(newValue: boolean) {
    this.showList = newValue;
  }

  public HideActionTransferPackage(paymentStatus: number) {
    if (paymentStatus !== PaymentStatus.Approve) {
      return false;
    }
    return true;
  }

  public HideActionCancelPackage(peakPaymentConnectV2Id: number) {
    if (peakPaymentConnectV2Id === PeakPaymentConnectV2Id.None) {
      return false;
    }
    return true;
  }

  public HideActionApprovePackage(paymentStatus: number, puunReceiptId: number) {
    if (paymentStatus !== PaymentStatus.Waiting || puunReceiptId === PuunReceiptId.None) {
      return false;
    }
    return true;
  }

  public HideActionViewReceipt(paymentStatus: number, puunReceiptId: number) {
    if (paymentStatus === PaymentStatus.Approve && puunReceiptId === PuunReceiptId.None) {
      return false;
    }
    return true;
  }

  public HideActionViewTaxedDetail(paymentStatus: number, whtId: number) {
    if (paymentStatus === PaymentStatus.Approve && whtId === WhtId.None) {
      return false;
    }
    return true;
  }

  public SwapMrchantPackage() {
    this.isShowSwapMerchantModal = true;
  }

  public TransferPackage() {
    this.isShowTransferPackageModal = true;
  }

  public CancelPaymentPackage() {
    this.isShowCancelPaymentPackageModal = true;
  }
  public ChangeSuperuser(merchantId: number) {
    localStorage.setItem("mechantName", this.item.merchantName);
    const routeData = this.$router.resolve({
      name: "changeSuperuser",
      query: { emi: merchantId.PeakEncryption() },
    });
    window.open(routeData.href, "_blank");
  }
  public ApproveUpgradePackage() {
    const input = {
      merchantId: this.item.merchantId,
      peakPaymentConnectV2Id: this.item.peakPaymentConnectV2Id,
    };
    this.DoApproveUpgradePackage(input);
  }

  public GetConvertMerchantAFPMenuText() {
    this.convertMerchantAFPMenuText = this.isAFPPackage()
      ? "เปลี่ยนประเภทกิจการ<br/>เป็นแพ็กเกจปกติ"
      : "เปลี่ยนประเภทกิจการ<br/>เป็นแพ็กเกจ AFP";
  }

  public ViewReceiptDetail() {
    const encodeReceiptId = btoa(this.item.puunReceiptId?.toString());
    const encodeMerchantId = btoa(process.env.VUE_APP_PuunMerchantId?.toString() || "");
    window.open(
      `${process.env.VUE_APP_PeakAccount}income/receiptDetail?emi=${encodeMerchantId}&eii=${encodeReceiptId}`,
      "_blank"
    );
  }
  public ViewTaxedDetail() {
    const encodeMerchantId = btoa(process.env.VUE_APP_PuunMerchantId?.toString() || "");
    const encodeWhtReferenceTransactionNumber = btoa(this.item.whtReferenceTransactionNumber?.toString());
    window.open(
      `${process.env.VUE_APP_PeakAccount}finance/taxed?emi=${encodeMerchantId}&search=${encodeWhtReferenceTransactionNumber}`,
      "_blank"
    );
  }
  public ConvertPackageAFP() {
    this.DoGetConvertMerchantPackageType({ merchantId: this.item.merchantId });
  }
  public isAFPPackage() {
    return [PeakPackage.AFPFree, PeakPackage.AFPLedger, PeakPackage.AFPAccountant].includes(
      this.item.currentMainPackage
    );
  }
}
