






































































import { LoadingStatusType } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { MerchantDropdownModel } from "@/model/merchant/merchantDropdownModel";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreMerchant = namespace("Merchant");

@Component
export default class ModalTranferPackage extends Vue {
  @Prop({ required: true }) readonly item!: any;
  private isShowTransferPackageModal = true;
  private merchantDropdownModelList: Array<MerchantDropdownModel> = [];
  private merchantDropdownModelSelectValue: any = null;
  private isCanDoActionTransferPackage = true;
  private isAsana = true;
  private asana = "";
  private remark = "";
  private searchTerm = "";
  @StoreMerchant.Getter
  private getMerchantListBySuperUserId!: Array<MerchantDropdownModel>;

  @StoreMerchant.Getter
  private getMerchantListBySuperUserIdStatus!: LoadingStatusType;

  @StoreMerchant.Action
  private DoGetMerchantListBySuperUserId!: (superUserId: number) => void;

  @Watch("isShowTransferPackageModal")
  isShowTransferPackageModalChanged(newVal: boolean) {
    if (!this.isShowTransferPackageModal) {
      this.$emit("update:isShowTransferPackageModal", false);
    }
  }

  @Watch("getMerchantListBySuperUserIdStatus", { immediate: true })
  getMerchantListBySuperUserIdStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      let merchantDropdownModelListObj =
        this.getMerchantListBySuperUserId.length > 0 ? NewObject(this.getMerchantListBySuperUserId) : [];
      let merchantDropdownModelListObjNew: any = [];
      merchantDropdownModelListObj.forEach((element: any) => {
        if (element.merchantId != this.item.merchantId) {
          merchantDropdownModelListObjNew.push(element);
        }
      });
      this.merchantDropdownModelList = merchantDropdownModelListObjNew;
    }
  }

  created() {
    this.GetMerchantListBySuperUserId(this.item.superUserId);
  }

  public GetMerchantListBySuperUserId(superUserId: number) {
    this.DoGetMerchantListBySuperUserId(superUserId);
  }

  public GetValueSelect(merchantIdSelect: any) {
    this.merchantDropdownModelSelectValue = this.merchantDropdownModelList?.find(
      (x) => x.merchantId === merchantIdSelect
    );
    this.ValidateTransferPackage();
  }
  /*
  public SearchMerchant(searhTerm:any)
  {
     this.merchantDropdownModelList = this.merchantDropdownModelList.filter((merchant) => {
        return merchant.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
      });
  }*/
  public GetAsana(asana: any) {
    this.ValidateAsana();
  }

  public ShowModalDetailConfirmTransferPackage() {
    if (this.ValidateTransferPackage() && this.ValidateAsana()) {
      this.$emit("update:merchantDropdownModelSelect", this.merchantDropdownModelSelectValue);
      this.$emit("update:isShowConfirmTransferPackageModal", true);
      this.$emit("update:isShowTransferPackageModal", false);
      this.$emit("update:asana", this.asana);
      this.$emit("update:remark", this.remark);
    }
  }

  public ValidateTransferPackage() {
    if (this.item !== null && this.merchantDropdownModelSelectValue !== null) {
      this.isCanDoActionTransferPackage = true;
    } else {
      this.isCanDoActionTransferPackage = false;
    }
    return this.isCanDoActionTransferPackage;
  }
  public ValidateAsana() {
    if (this.asana !== null && this.asana !== "") {
      this.isAsana = true;
    } else {
      this.isAsana = false;
    }
    return this.isAsana;
  }
}
