






























import { LoadingStatusType } from "@/enums/enums";
import { MerchantTableViewModel } from "@/model/merchant//merchantTableViewModel";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreMerchant = namespace("Merchant");
const StoreLoading = namespace("Loading");

@Component
export default class ModalConfirmTransferPackage extends Vue {
  @Prop({ required: true }) readonly merchantDropdownModelSelect!: any;
  @Prop({ required: true }) readonly item!: any;
  @Prop({ required: true }) readonly asana!: string;
  @Prop({ required: true }) readonly remark!: string;

  private isShowConfirmTransferPackageModal = true;
  private textConfirmTransferPackage = "";

  @StoreMerchant.Getter
  private getMerchantTableView!: any;

  @StoreMerchant.Getter
  private getTransferPackageLoadingStatus!: LoadingStatusType;

  @StoreMerchant.Action
  private DoGetMerchantTable!: (merchantTableViewModel: MerchantTableViewModel) => void;

  @StoreMerchant.Action
  private DoTransferPackage!: (inputRequest: any) => void;

  @StoreLoading.Action
  private IsLoading!: (isLoading: boolean) => void;

  created() {
    if (this.merchantDropdownModelSelect) {
      this.textConfirmTransferPackage =
        "คุณต้องการโอนย้ายวันคงเหลือกิจการ " +
        this.item.merchantId +
        " - " +
        this.item.merchantName +
        " Package : " +
        this.item.currentMainPackage +
        " Expired : " +
        this.item.expiredDateTime +
        " กับกิจการ " +
        this.merchantDropdownModelSelect.merchantId +
        " - " +
        this.merchantDropdownModelSelect.merchantName +
        " Package : " +
        this.merchantDropdownModelSelect.currentMainPackage +
        " Expired : " +
        this.merchantDropdownModelSelect.expiredDateTime;
    }
  }

  @Watch("getTransferPackageLoadingStatus")
  getTransferPackageLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Loading) {
      this.IsLoading(true);
    } else if (newVal == LoadingStatusType.Success) {
      this.IsLoading(false);
      this.CancelModalConfirmTransferPackage();
      this.DoGetMerchantTable(this.getMerchantTableView);
    } else {
      this.IsLoading(false);
      this.CancelModalConfirmTransferPackage();
    }
  }

  public CancelModalConfirmTransferPackage() {
    this.$emit("update:isShowConfirmTransferPackageModal", false);
    this.$emit("update:item", null);
    this.$emit("update:asana", this.asana);
    this.$emit("update:remark", this.remark);
  }

  public DoConfirmTransferPackage(
    merchantDetailModelSelect = this.item,
    merchantDropdownModelSelect = this.merchantDropdownModelSelect,
    asana = this.asana,
    remark = this.remark
  ) {
    const inputRequest = {
      merchantDetailModel: merchantDetailModelSelect,
      merchantDropdownModel: merchantDropdownModelSelect,
      asana: asana,
      remark: remark,
    };
    this.DoTransferPackage(inputRequest);
  }
}
