






















































































































































import { LoadingStatusType, PeakPackage, UserPackageType } from "@/enums/enums";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const StoreMerchant = namespace("Merchant");

@Component
export default class modalConvertPackageAFP extends Vue {
  @Prop({ required: true }) readonly item!: any;
  @Prop({ required: true }) readonly convertMerchantDetail!: any;

  @StoreMerchant.Getter
  private getConvertMerchantPackageToAFPLoadingStatus!: LoadingStatusType;

  @StoreMerchant.Action
  private DoConvertMerchantToAFP!: (input: any) => void;

  private isShowConvertPackageAFPModal = true;
  private isAsana = true;
  private asana = "";
  private remark = "";
  private dateCurrent = new Date();
  private startDate = this.dateCurrent.setDate(this.dateCurrent.getDate() + 1);
  private endDate = this.dateCurrent.setDate(this.dateCurrent.getDate() + 1);
  private datefrom = new Date(this.startDate - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
  private menufrom = false;
  private modalfrom = false;
  private menu2from = false;
  private packageSelectedValidateError = false;
  private packageTypeList: Array<any> = [
    { value: PeakPackage.Free, text: "FREE" },
    { value: PeakPackage.Basic, text: "BASIC" },
    { value: PeakPackage.Pro, text: "PRO" },
    { value: PeakPackage.ProPlus, text: "PRO PLUS" },
    { value: PeakPackage.Premium, text: "PREMIUM" },
  ];
  private packageSelected = null;

  @Watch("isShowConvertPackageAFPModal")
  isShowTransferPackageModalChanged(newVal: boolean) {
    if (!this.isShowConvertPackageAFPModal) {
      this.$emit("update:isShowConvertPackageAFPModal", false);
    }
  }
  @Watch("getConvertMerchantPackageToAFPLoadingStatus")
  getConvertMerchantPackageToAFPLoadingStatusChanged(newVal: number) {
    if (newVal == LoadingStatusType.Success) {
      alert(
        `Successfully converted to ${
          !this.isAFPPackage() ? "AFP FREE" : this.GetPackageName(this.packageSelected)
        } Package`
      );
      this.HideModal();
    }
  }
  public ValidateAsana() {
    if (this.asana !== null && this.asana !== "") {
      this.isAsana = true;
    } else {
      this.isAsana = false;
    }
    return this.isAsana;
  }
  public GetAsana(asana: any) {
    this.ValidateAsana();
  }

  public isAFPPackage() {
    return this.convertMerchantDetail.merchant.isAccountingFirm;
  }

  public GetPackageName(packageId: any) {
    switch (packageId) {
      case PeakPackage.None:
        return "";
      case PeakPackage.Trial:
        return "Trial";
      case PeakPackage.Free:
        return "Free";
      case PeakPackage.Basic:
        return "Basic";
      case PeakPackage.Pro:
        return "Pro";
      case PeakPackage.ProPlus:
        return "Pro Plus";
      case PeakPackage.AFPFree:
        return "AFP Free";
      case PeakPackage.AFPLedger:
        return "AFP Ledger";
      case PeakPackage.AFPAccountant:
        return "AFP Accountant";
      case PeakPackage.Premium:
        return "Premium";
    }
  }

  public ConvertMerchantPackageType() {
    let input = {};
    let validateField = false;
    if (!this.isAFPPackage()) {
      validateField = true;
      input = {
        merchantId: this.convertMerchantDetail.merchant.id,
        accountingFirmId: this.convertMerchantDetail.superAdmin.afp.id,
        peakPackageType: UserPackageType.AFP,
        merchantCode: this.convertMerchantDetail.merchant.code,
        internalCode: this.convertMerchantDetail.superAdmin.afp.internalCode,
        merchantActionOption: {
          asanaNumber: this.asana,
          remark: this.remark,
        },
      };
    } else {
      if (this.packageSelected) {
        validateField = true;
      } else {
        this.packageSelectedValidateError = true;
      }
      input = {
        merchantId: this.convertMerchantDetail.merchant.id,
        accountingFirmId: this.convertMerchantDetail.superAdmin.afp.id,
        peakPackageType: UserPackageType.Normal,
        peakPackage: this.packageSelected,
        newExpireDatetime: this.datefrom,
        merchantCode: this.convertMerchantDetail.merchant.code,
        internalCode: this.convertMerchantDetail.superAdmin.afp.internalCode,
        merchantActionOption: {
          asanaNumber: this.asana,
          remark: this.remark,
        },
      };
    }

    if (this.ValidateAsana() && validateField) {
      this.DoConvertMerchantToAFP(input);
    }
  }

  HideModal() {
    this.isShowConvertPackageAFPModal = false;
  }
}
